import React, { useState, useEffect } from "react";
import routes from "../routes";
import axios from '../services/axios';
import { useParams, useNavigate } from "react-router-dom";
import PhoneMask from "./PhoneMask"; // Importe o componente PhoneMask
import Clock from './Clock';
import { FaRegUserCircle } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import 'bootstrap/dist/css/bootstrap.min.css'; // Importe o CSS do Bootstrap
import Loading from './Loading';
import Card from 'react-bootstrap/Card';
import iconEye from "../Assets/Eye.png";
import Badge from 'react-bootstrap/Badge';
import CustomModal from "./CustomModal";
import { formatDate, formatPhone, formatCNPJ, formatMoney } from '../functions/formater';



const NpsCNPJ = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [produtos, setProdutos] = useState([])
  const [produtosLista, setProdutosLista] = useState([]);
  const [activeFilter, setActiveFilter] = useState('todas'); // Estado para o filtro ativo
  const { cpfCnpj } = useParams();
  const [selectedProduto, setSelectedProduto] = useState(null);

  useEffect(() => {
    const requests = async () => {
      try {
        setIsLoading(true)
        await Promise.all([
          getProdutos(cpfCnpj),
          getCliente(cpfCnpj)
        ]);
      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      } finally {
        setIsLoading(false)
      }
    };

    requests();
  }, [cpfCnpj]);

  const getCliente = async (cpfCnpj) => {
    try {
      const response = await axios.get(routes.authentication + cpfCnpj); // Assumindo que o endpoint seja algo como /clientes/:cpfCnpj
      setCliente(response.data);
      const telefoneCelular = response.data.telefone || response.data.celular;
      if (telefoneCelular) {
        setTelefone(telefoneCelular);
      }
    } catch (error) {
      console.error("Erro ao obter dados do cliente:", error);
      setCliente(null);
    }
  };

  const getProdutos = async (cpfCnpj) => {
    try {
      const response = await axios.get(routes.findProdutosByCNPJ + cpfCnpj);
      setProdutos(response.data.produtos);
      setProdutosLista(response.data.produtos);
    } catch (error) {
      console.error("Erro ao obter dados dos produtos:", error);
      setProdutos([]);
      setProdutosLista([]);
    }
  };

  const filtrarProdutos = (filter) => {
    setActiveFilter(filter);

    if (!produtos) return

    if (filter && filter !== 'todas') {
      setProdutosLista(produtos.filter(product => product.operacao.includes(filter)));
    } else {
      setProdutosLista(produtos);
    }
  };

  const voltarOnClick = () => {
    navigate("/");
  };

  const handleOpenCustomModal = (produto) => {
    setSelectedProduto(produto);
  };

  const handleCloseCustomModal = () => {
    setSelectedProduto(null);
  };

  function getBadgeClass(status) {
    if (status === 'analise') return 'primary';
    if (status === 'recusado') return 'danger';
    if (status === 'aprovado') return 'success';
  }

  function getStatusText(status) {
    if (status === 'analise') return 'Análise';
    if (status === 'recusado') return 'Recusado';
    if (status === 'aprovado') return 'Aprovado';
  }



  return (
    <div className="NPS-section">
      {isLoading && <Loading />}
      {cliente ? (
        <div className="NPS-card">
          {/* Informações do cliente para desktop */}
          <div className="nps-card-header col-lg-9 col-md-9 ">
            <div>
              <span><FaRegUserCircle /> {cliente.nome}</span>
              <p></p>
              <span><FaAddressCard /> {formatCNPJ(cliente.cnpj)}</span>
              <p></p>
              <span><FaPhone /> {formatPhone(cliente.telefone)}</span>
            </div>
            <div>
              <button className="button-top botao-voltar-nps" onClick={voltarOnClick}>Voltar</button>
            </div>
          </div>

          <div className="nps-card-bottom col-lg-9 col-md-9 col-xs-12">

            <div className="nps-card-right col-lg-12 col-md-12 col-xs-12">
              {/* Botões de filtro */}
              <div className="nps-right-buttons col-lg-12 col-md-12">
                <button className={`button-nps ${activeFilter === 'todas' ? 'active' : ''}`} onClick={() => filtrarProdutos('todas')}>Todas</button>
                <button className={`button-nps ${activeFilter === 'credito' ? 'active' : ''}`} onClick={() => filtrarProdutos('credito')}>Créditos</button>
                <button className={`button-nps ${activeFilter === 'investimento' ? 'active' : ''}`} onClick={() => filtrarProdutos('investimento')}>Investimentos</button>
              </div>
              {/* Tabela de produtos filtrados */}
              {produtosLista.length ? (
                <table className="table-class col-lg-12 col-md-12 col-xs-12">
                  <thead>
                    <tr className="cabecalhoTabela">
                    <th className="thTabela">DT Inicio</th>
                      <th className="thTabela">DT Fim</th>
                      <th className="thTabela">Parcelas</th>
                      <th className="thTabela">Banco</th>
                      <th className="thTabela">Produto</th>
                      <th className="thTabela">Valor</th>
                      <th className="thTabela">Status Final</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-css">
                    {produtosLista.map((item, index) => (
                      <tr className={`cabecalhoTabela ${index % 2 === 0 ? 'gray-row' : 'white-row'}`} key={index}>
                        <td className="tdTabela">{formatDate(item.data_inicio)}</td>
                        <td className="tdTabela">{formatDate(item.data_fim)}</td>
                        <td className="tdTabela">{item.parcelas}</td>
                        <td className="tdTabela">{item.banco}</td>
                        <td className="tdTabela">{item.produto}</td>
                        <td className="tdTabela">{formatMoney(item.valor)}</td>
                        <td className="tdTabela">
                          <h6> <Badge bg={getBadgeClass(item.statusFinal)}> {getStatusText(item.statusFinal)}</Badge> </h6>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              ) : (
                <div>
                  <Card bg='secondary' body className="text-center" style={{ color: "white" }}>Não há produtos para esse filtro</Card>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Carregando informações do cliente...</div>
      )}
      {selectedProduto && (
        <CustomModal
          visible={!!selectedProduto}
          onClose={handleCloseCustomModal}
          produto={selectedProduto}
        />
      )}
    </div>
  );
};

export default NpsCNPJ;
