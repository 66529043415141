import Footer from "../Components/Footer";
import NavbarLog from "../Components/NavbarLog";
import Nps from "../Components/Nps";
import TradingV from "../Components/Trading";

import "../App.css";

function PNps() {
  return (
    <div className="App">
      <NavbarLog />
      <TradingV />
      <Nps />
      <Footer />
    </div>
  );
}

export default PNps;