// const API_URL = 'https://api.fazcredito.com.br';

/*****
  Essa url deve ser utilizada apenas para testes(linha 7), quando comitar esse arquivo essa linha deverá ser comentada

*****/
 const API_URL = 'http://localhost:3001';


const authentication = `${API_URL}/authentication/`;

const sendMail = `${API_URL}/sendMail/`;

const saveProduto = `${API_URL}/saveProduto/`;

const findProdutosByCPF = `${API_URL}/findprodutosbycpf/`;

const findProdutosByCNPJ = `${API_URL}/findprodutosbycnpj/`;

const findprodutosempresa = `${API_URL}/findprodutosempresa/`;

const login = `${API_URL}/login/`;

const saveChangeStatusEmpresa = `${API_URL}/savechangestatusempresa/`;

const saveAssociado = `${API_URL}/saveassociado/`;



const routes = {
  API_URL,
  authentication,
  sendMail,
  saveProduto,
  findProdutosByCPF,
  findProdutosByCNPJ,
  findprodutosempresa,
  login,
  saveChangeStatusEmpresa,
  saveAssociado
};

export default routes;