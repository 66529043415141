import React from 'react';

function VideoPlayer() {
    return (
        <div className="videoArea">
            <h1>Veja o que temos para você!</h1>
            {/* <video className="videoPlayer" controls width="800">
                <source src={VideoApresentacao} type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
            </video> */} {/*Video display in site*/}
            <iframe className="iframe-apresentacao" width="560" height="315" src="https://www.youtube.com/embed/Ror--bkubVA?si=EJYFysrw6rm-IMKN" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
    );
}

export default VideoPlayer;
