import React from "react";

const PhoneMask = ({ phoneNumber }) => {
  // Verifica se o número de telefone é válido
  if (!phoneNumber) return null;

  // Aplica a máscara de telefone
  const formattedPhone = `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7)}`;

  return <span>{formattedPhone}</span>;
};

export default PhoneMask;
