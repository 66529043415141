import React, { useEffect } from 'react';

const TradingV = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            symbols: [
                {
                    proName: "FX_IDC:EURUSD",
                    title: "EUR to USD"
                },
                {
                    proName: "FX_IDC:JPYBRL",
                    title: "YEN to BRL"
                },
                {
                    proName: "FX_IDC:NZDBRL",
                    title: "NZD to BRL"
                },
                {
                    proName: "FX_IDC:USDBRL",
                    title: "USD to BRL"
                },
                {
                    proName: "FX_IDC:PGKBRX",
                    title: "PGK to BRL"
                },
                {
                    proName: "FX_IDC:MNTBRX	",
                    title: "MNT to BRL"
                },
                
            ],
            showSymbolLogo: true,
            isTransparent: false,
            displayMode: "adaptive",
            colorTheme: "light",
            locale: "br"
        });

        document.getElementsByClassName('tradingview-widget-container__widget')[0].appendChild(script);

        return () => {
            document.getElementsByClassName('tradingview-widget-container__widget')[0].innerHTML = '';
        };
    }, []);

    return (
        <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
            </div>
        </div>
    );
};

export default TradingV;
