import { React, useEffect, useState } from 'react';
import Footer from "../Components/Footer";
import NavbarLog from "../Components/NavbarLog";
import FormBacklogBP from "../Components/FormBacklogBP";
import { useNavigate } from "react-router-dom";
import jwtValidator from '../functions/JWTTokenValidator';
import axios from 'axios';
import routes from "../routes"
import "../App.css";
import Loading from '../Components/Loading';
import encrypt from '../functions/Encrypt';
import TradingV from '../Components/Trading';


function Backlog() {
  const navigate = useNavigate();
  const [produtosEmpresa, setProdutosEmpresa] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [qtdProdutos, setQtdProdutos] = useState(0);

  useEffect(() => {
      validateToken(); 
  }, []);

  const validateToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return navigate("/login");
    }
    const valido = await jwtValidator(token);

    if (!valido) {
      setLoading(false);
      localStorage.removeItem('token');
      return navigate("/login");
    }
    return await findProducts();
  };

  const findProducts = async () => { 
    setLoading(true);   
    const documento = await encrypt(localStorage.getItem("cnpjAssociacao"));
    const request = await axios.get(routes.findProdutosByCNPJ + documento);
    if (request.data.produtos) {
      setProdutosEmpresa(request.data.produtos);
      setQtdProdutos(request.data.qtdProdutos)
      
    setLoading(false);
    }
  };

  return (
    <div className="App">
      {isLoading && <Loading />}
      <div style={{display:"none"}}> <TradingV/>  </div>
      {!isLoading && (
        <>
          <NavbarLog />
          <FormBacklogBP produtos={produtosEmpresa}  qtdProdutos={qtdProdutos} />
          <Footer />
        </>
      )}
    </div>
  );
}


export default Backlog;