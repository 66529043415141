import React from "react";
import Eugenio from "../Assets/Eugenio.png";
import Aguinaldo from "../Assets/Aguinaldo.png"
import Guilherme from "../Assets/Guilherme.png"
import Maicon from "../Assets/Maicon.png"


const TeamMember = ({ name, role, imageUrl }) => (
    <div className="team-member">
        <img src={imageUrl} alt={`${name}`} className="team-member-img" />
        <h3>{name}</h3>
        <p>{role}</p>
    </div>
);

const Team = () => {
    const teamMembers = [
        { name: 'Aguinaldo', role: 'Co-founder', imageUrl: Aguinaldo },
        { name: 'Eugenio', role: 'Founder', imageUrl: Eugenio },
        { name: 'Guilherme', role: 'Co-founder', imageUrl: Guilherme },
        { name: 'Maicon', role: 'Founder', imageUrl: Maicon }
    ];

    return (
        <div className="team-area">
            <h1>Nossa equipe:</h1>
            <div className="team">
            {teamMembers.map((member, index) => (
                <TeamMember
                    key={index}
                    name={member.name}
                    role={member.role}
                    imageUrl={member.imageUrl}
                />
            ))}
            </div>
        </div>
    );
};

export default Team;