import React, { useState, useEffect } from "react";
import routes from "../routes";
import axios from '../services/axios';
import { useParams, useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { FaAddressCard, FaPhone } from "react-icons/fa6";
import NpsProdutos from "./NpsProdutos.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './Loading';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';


const Nps = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { cpfCnpj } = useParams();
  const [cliente, setCliente] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produtosLista, setProdutosLista] = useState('');
  const [activeFilter, setActiveFilter] = useState('todas'); // Estado para o filtro ativo
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    const requests = async () => {
      try {
        setIsLoading(true)
        await Promise.all([
          getProdutos(cpfCnpj),
          getCliente(cpfCnpj)
        ]);
      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      } finally {
        setIsLoading(false)
      }
    };

    requests();
  }, [cpfCnpj]);

  const getCliente = async (cpfCnpj) => {
    if (cliente) return;

    try {
      const response = await axios.get(routes.authentication + cpfCnpj);
      setCliente(response.data);

      const telefoneCelular = response.data.telefone || response.data.celular;
      if (telefoneCelular) {
        setTelefone(telefoneCelular);
      }
    } catch (error) {
      console.error("Erro ao obter dados do cliente:", error);
      setCliente(null);
    }
  };

  const getProdutos = async (cpfCnpj) => {
    try {
      const response = await axios.get(routes.findProdutosByCPF + cpfCnpj);
      const prod = response.data?.[0]?.produto;

      if (!prod)
        setShowMessage(true)
      else {
        setProdutos(response.data);
        setProdutosLista(response.data);
      }
    } catch (error) {
      console.error("Erro ao obter dados dos produtos:", error);
      setProdutos([]);
      setProdutosLista([]);
    }
  };

  const voltarOnClick = () => {
    navigate("/");
  };

  const filtrarProdutos = (filter) => {
    setActiveFilter(filter);
    if (showMessage) return

    if (filter && filter !== 'todas') {
      setProdutosLista(produtos.filter(product => product.operacao.includes(filter)));
    } else {
      setProdutosLista(produtos);
    }
  };

  function getBadgeClass(status) {
    if (status === 'analise') return 'primary';
    if (status === 'negado') return 'danger';
    if (status === 'aprovado') return 'success';
  }

  function getStatusText(status) {
    if (status === 'analise') return 'Análise';
    if (status === 'negado') return 'Negado';
    if (status === 'aprovado') return 'Aprovado';
  }

  return (
    <div className="NPS-section">
      {isLoading && <Loading />}
      {cliente ? (
        <div className="NPS-card">
          <div className="nps-card-header col-lg-10 col-md-10">
            <div>
              <h1><FaRegUserCircle />{cliente.nome}</h1>
              {/* <Clock /> */}
              <h6><FaAddressCard /> {cliente.cpf}</h6>
              {telefone && (
                <h6><FaPhone />{telefone}</h6>
              )}
            </div>
            <div>
              <button className="button-top d-sm-block botao-voltar-nps" onClick={voltarOnClick}>Voltar</button>
            </div>
          </div>

          <div className="nps-card-bottom col-lg-10 col-md-10 col-xs-12">
            <div className="nps-card-right col-lg-12 col-md-12 col-xs-12">
              <div className="nps-right-buttons col-lg-12 col-md-12">
                <button className={`button-nps ${activeFilter === 'todas' ? 'active' : ''}`} onClick={() => filtrarProdutos('todas')}>Todas</button>
                <button className={`button-nps ${activeFilter === 'credito' ? 'active' : ''}`} onClick={() => filtrarProdutos('credito')}>Créditos</button>
                <button className={`button-nps ${activeFilter === 'investimento' ? 'active' : ''}`} onClick={() => filtrarProdutos('investimento')}>Investimentos</button>
              </div>
              {showMessage ? (
                <div>
                  <Card bg='secondary' body className="text-center card-bootstrap">Não há produtos para esse filtro</Card>
                </div>
              ) : (
                produtosLista && (
                  <table className="table-class col-lg-12 col-md-12 col-xs-12">
                    <thead>
                      <tr className="tre col-12">
                        <th className="col-2">DT Início</th>
                        <th className="col-2">DT Fim</th>
                        <th className="col-1">Parcelas</th>
                        <th className="col-2">Produto</th>
                        <th className="col-2">Banco</th>
                        <th className="col-2">Status</th>
                        {cliente.isBp && (
                          <th className="col-1">Status BP</th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="tbody-css col-12">
                      {produtosLista.map((item, index) => (
                        <tr className={`tre ${index % 2 === 0 ? 'gray-row' : 'white-row'}`} key={index}>
                          <td className="col-2">{item.data_fim}</td>
                          <td className="col-2">{item.data_inicio}</td>
                          <td className="col-1">{item.parcelas}</td>
                          <td className="col-2">{item.produto}</td>
                          <td className="col-2">{item.banco}</td>
                          <td className="col-2">
                            <h6> <Badge bg={getBadgeClass(item.statusBanco)}> {getStatusText(item.statusBanco)}</Badge> </h6>
                          </td>
                          {cliente.isBp && (
                            <td className="col-1">
                              <h6> <Badge bg={getBadgeClass(item.statusEmpresa)}> {getStatusText(item.statusEmpresa)}</Badge> </h6>
                            </td>
                          )}
                        </tr>
                      ))}

                    </tbody>

                  </table>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Carregando informações do cliente...</div>
      )}
      <NpsProdutos cpf={cpfCnpj} />
    </div>
  );
};

export default Nps;
