function formatDate(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR'); 
}

function formatPhone(phone) {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `(${match[1]})` : '';  
    return [intlCode, match[2], '-', match[3]].join(''); 
  }
  return phone; 
}

function formatCPF(cpf) {
  const cleaned = ('' + cpf).replace(/\D/g, ''); 
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`; 
  }
  return cpf; 
}

function formatCNPJ(cnpj) {
  const cleaned = ('' + cnpj).replace(/\D/g, ''); 
  const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`; 
  }
  return cnpj; 
}


function formatMoney(value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export { formatDate, formatPhone, formatCPF, formatCNPJ, formatMoney };
