import About from "../Components/About";
import Home from "../Components/Home";
import Work from "../Components/Work";
import "../App.css";
import ContactGraphic from "../Components/ContactGraphic";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import TradingV from "../Components/Trading";
import VideoPlayer from "../Components/Video";
// import Contact from "../Components/Contact";
// import WhatsBtn from "./Components/WhatsBtn";

function App() {
  return (
    <div className="App">
      <Navbar/>
      <TradingV/>
      <Home/>
      <Work/>
      <ContactGraphic/>
      <About/>
      {/* <Contact/> */}
      {/* <WhatsBtn /> */}
      <VideoPlayer/>
      <Footer />
    </div>
  );
}

export default App;
