import React, { useState } from "react";
import grafic from "../Assets/dados.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import InputMask from 'react-input-mask';
import cpfCnpjValidator from '../functions/cpfCnpjValidator'
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from 'axios';
import routes from "../routes";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    cpf: ''
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const valido = cpfCnpjValidator(formData.cpf)

    if(!valido){
      return Swal.fire({
        icon: "error",
        title: "Opss",
        text: "Informe um CPF ou CNPJ valido para prosseguir",
      })
    }

    const obj = {
      ...formData,
      type: "primeiroContato"
    }

    await axios.post(routes.sendMail, obj).then(() => {
      Swal.fire({
        icon: "success",
        title: "Sucesso!!",
        text: "E-mail enviado para nosso time de especialistas",
      }).then(()=>{
        setShowModal(false)
      })

    }) 
  };

  return (
    <div className="contact-page-wrapper" id="contato">
      <div className="contact-card">
        <div className="contact-text">
          <h3 className="primary-heading-contact">Gostaria de mais informações sobre os produtos e serviços...</h3>
          <button className="secondary-button-contact" onClick={handleShowModal}>Entre em contato</button>
        </div>
        <div className="contact-image">
          <img className="contact-image-img" src={grafic} alt="Imagem de gráfico financeiro" />
        </div>
      </div>

      {showModal && (
        <div className={`modal fade show d-block`} tabIndex="-1" style={{ display: 'block', backgroundColor: '#ffffff75', paddingTop:'10vh' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header" style={{ display: 'grid' }}>
                <h3 className="modal-title">Preencha com seus dados</h3>
                <h6 className="modal-title">Nosso time de especialistas entrará em contato</h6>
                <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="nome" className="form-label">Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nome"
                      name="nome"
                      value={formData.nome}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="telefone" className="form-label">Telefone</label>
                    <InputMask
                      mask="(99) 99999-9999"
                      className="form-control"
                      id="telefone"
                      name="telefone"
                      value={formData.telefone}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="cpf" className="form-label">CPF</label>
                    <input
                      type="text"
                      className="form-control"
                      id="cpf"
                      name="cpf"
                      value={formData.cpf}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancelar</button>
                    <button type="submit" className="btn btn-primary">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
