import React from "react";
import Team from "./Team";
import financeiroNovo from "../Assets/Grafico2.jpg"

const About = () => {
  return (
    <>
    <div id="empresa" className="about-section-container">
      <div className="about-section-text-container">
        <p className="primary-subheading">A empresa</p>
        <h1 className="primary-heading">
          Sobre nós
        </h1>
        <p className="primary-text">
          Com o objetivo de buscar as melhores soluções em cada vertical do segmento financeiro, a <span className="home-text-span">FAZCREDITO</span> atua com uma equipe de especialistas atuando nas melhores oportunidades sejam em crédito ou investimentos para você.
        </p>
        <p className="primary-text">
          Em ambiente tecnológico e com acompanhamento digital, a <span className="home-text-span">FAZCREDITO</span> conecta as soluções com atuação humanizada para melhor entender o momento de cada um e atende-lo conforme suas necessidades.
        </p>
      </div>
    </div>
    <Team />
    <div className="grafico-area">
      <h1>Acompanhamento e análise em tempo real pela confederação e federações.</h1>
      <img src={financeiroNovo} alt=""/>
    </div>
    </>
  );
};

export default About;
