import Footer from "../Components/Footer";
import NavbarLog from "../Components/NavbarLog";
import NpsCNPJ from "../Components/NpsCnpj";
import TradingV from "../Components/Trading";
import "../App.css";

function PNpsCNPJ() {
    return (
        <div className="App">
            <NavbarLog />
            <TradingV />
            <NpsCNPJ />
            <Footer />
        </div>
    );
}

export default PNpsCNPJ;