import React, { useState } from "react";
import Logo from "../Assets/Logotipo.png";
import { BsWhatsapp } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import ModalFooter from "./ModalFooter"; // Ajuste o caminho conforme necessário
import cpfCnpjValidator from '../functions/cpfCnpjValidator';
import "sweetalert2/dist/sweetalert2.css";
import axios from 'axios';
import routes from "../routes";
import Swal from "sweetalert2";
import InputMask from 'react-input-mask';


const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  const openModal = (item) => {
    setModalItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalItem(null);
  };

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    cpf: ''
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const valido = cpfCnpjValidator(formData.cpf)

    if(!valido){
      return Swal.fire({
        icon: "error",
        title: "Opss",
        text: "Informe um CPF ou CNPJ valido para prosseguir",
      })
    }

    const obj = {
      ...formData,
      type: "primeiroContato"
    }

    await axios.post(routes.sendMail, obj).then(() => {
      Swal.fire({
        icon: "success",
        title: "Sucesso!!",
        text: "E-mail enviado para nosso time de especialistas",
      }).then(()=>{
        setShowModal(false)
      })

    }) 
  };

  const missionVisionValuesItem = {
    title: "Missão, Visão e Valores",
    missao: `Missão: Proporcionar soluções financeiras inovadoras e personalizadas, capacitando nossos clientes a alcançar suas metas econômicas com segurança e confiança, sempre com transparência e excelência no atendimento.`,
    visao: `Visão: Ser reconhecida como líder em serviços financeiros, destacando-se pela inovação, ética e compromisso com o sucesso dos clientes, transformando o mercado financeiro com tecnologia avançada e parcerias estratégicas.`,
    valores: `Valores:Integridade, honestidade e transparência. Excelência através de melhoria contínua e inovação. Cliente em primeiro lugar, superando expectativas. Compromisso com responsabilidade social e ambiental. Colaboração, trabalho em equipe e parcerias valorizados. Inovação com novas tecnologias para soluções financeiras diferenciadas.`,
  };

  return (
    <div className="footer-wrapper col-lg-12 col-md-12 col-xs-12">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="footer-icons">
          <a href="https://api.whatsapp.com/seu-link-do-whatsapp" target="_blank" rel="noopener noreferrer">
            <BsWhatsapp />
          </a>
          <a href="https://www.linkedin.com/seu-link-do-linkedin" target="_blank" rel="noopener noreferrer">
            <SiLinkedin />
          </a>
          <a href="https://www.facebook.com/seu-link-do-facebook" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com/faz.credito/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
      </div>
      <div className="footer-section-three">
        <div>
          <h1 className="span-titulo-left">Institucional</h1>
          <h1
            className="span-titulo-left"
            onClick={() => openModal(missionVisionValuesItem)}
            style={{ cursor: 'pointer' }}
          >
            Quem somos <br /> Missão, visão e valores
          </h1>
          <h1 className="span-titulo-left" onClick={handleShowModal} style={{ cursor: 'pointer' }}>Fale conosco</h1>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <h1 className="span-titulo">O que fazemos</h1>
          <span>Consignado Público</span>
          <span>Consignado Privado</span>
          <span>Seguros</span>
          <span>Refinanciamento</span>
          <span>Crédito Direto</span>
          <span>Investimentos</span>
          <span>Planejamento Financeiro</span>
          <span>Pesquisas: NPS e CSAT</span>
        </div>
        <div className="footer-section-columns">
          <h1 className="span-titulo">Nossa matriz</h1>
          <span>Rua 25 de Janeiro, 34</span>
          <span>Piratininga-SP</span>
          <span>Centro</span>
          <span>CEP: 17490-070</span>
          <span>CNPJ:55.362.758/0001-61</span>
        </div>
      </div>

      {isModalOpen && (
        <ModalFooter
          closeModalDescricao={closeModal}
          item={modalItem}
        />
      )}

      {showModal && (
        <div className={`modal fade show d-block`} tabIndex="-1" style={{ display: 'block', backgroundColor: '#ffffff75', paddingTop:'10vh' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header" style={{ display: 'grid' }}>
                <h3 className="modal-title">Preencha com seus dados</h3>
                <h6 className="modal-title">Nosso time de especialistas entrará em contato</h6>
                <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="nome" className="form-label">Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nome"
                      name="nome"
                      value={formData.nome}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="telefone" className="form-label">Telefone</label>
                    <InputMask
                      mask="(99) 99999-9999"
                      className="form-control"
                      id="telefone"
                      name="telefone"
                      value={formData.telefone}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="cpf" className="form-label">CPF</label>
                    <input
                      type="text"
                      className="form-control"
                      id="cpf"
                      name="cpf"
                      value={formData.cpf}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancelar</button>
                    <button type="submit" className="btn btn-primary">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
