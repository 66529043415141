import React, { useState, useEffect } from 'react';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []); // O array vazio assegura que o useEffect só seja executado uma vez

  return (
    <div>

      <p>{time.toLocaleTimeString()} - {time.toLocaleDateString()}</p>
    </div>
  );
};

export default Clock;