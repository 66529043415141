import React from "react";

const FormLogin = ({ cliente, closeModalForm }) => {
  console.log(cliente)

    return (
        <div className="loginModalBackground">
        <div className="formulario-area"> 
            <div className="loginModalTitleCLoseBtn">
                <button onClick={() => closeModalForm(false)}>X</button>
            </div>
            <form className="formulario" action="/submit_association_form" method="POST">
                <h2>Encaminhe ao nosso time de especialistas</h2>
                <label className="label-form">
                    Nome Associado:
                    {/* <input className="input-form" type="text" name="nome_associado" placeholder="Insira seu nome" required value={cliente.nome} /> */}
                </label>
{/*                 <label className="label-form">
                    Nome da Associação:
                    // <input className="input-form" type="text" name="nome_associacao" placeholder="Nome da associação" required value={cliente.} />
                </label> */}
                <label className="label-form">
                    Telefone:
                    {/* <input className="input-form" type="tel" name="telefone" placeholder="Telefone" required value={cliente.telefone} pattern="[0-9]{2}[0-9]{8,9}" /> */}
                </label>
                <label className="label-form">
                    Cidade:
                    {/* <input className="input-form" type="text" name="cidade" placeholder="Insira sua cidade" required value={cliente.cidade} /> */}
                </label>
                <label className="label-form">
                    Email:
                    {/* <input className="input-form" type="email" name="email" placeholder="Insira seu email" required value={cliente.email} /> */}
                </label>
                <input className="input-botao" type="submit" value="Enviar" />
            </form>
        </div>
        </div>
    );
};

export default FormLogin;