import React, { useState } from 'react';
import LoginModalSendMail from './LoginModalSendMail';

const ModalFooter = ({ closeModalDescricao, item }) => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  return (
    <div className="modalBackground">
      {loginModalOpen && <LoginModalSendMail isOpen={loginModalOpen} onClose={() => setLoginModalOpen(false)} item={item} />}
      <div className="modalContainer">
        <div className="modalTitleCLoseBtn">
          <button onClick={() => closeModalDescricao(false)}> X </button>
        </div>
        <h2>{item.title}</h2>
        <p>{item.missao}</p>
        <p>{item.visao}</p>
        <p>{item.valores}</p>
        <div className="modalContentButtons">
        </div>
      </div>  
    </div>
  );
};

export default ModalFooter;
