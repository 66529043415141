import { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { HiOutlineBars3 } from "react-icons/hi2";
// import userIcon from "../Assets/do-utilizador.png"
import Logo from "../Assets/Logotipo.png";
import { FaRegUserCircle } from "react-icons/fa";
import LoginModal from "./LoginModal";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // const batata = false

  const [hideHeader, setHideHeader] = useState(false);
  const menuOptions = [
    {
      text: "Soluções",
      icon: <HomeIcon />,
      href: "#solucoes",
    },
    {
      text: "Empresa",
      icon: <InfoIcon />,
      href: "#empresa",
    },
    {
      text: "Contato",
      icon: <PhoneRoundedIcon />,
      href: "#contato",
    },
    {
      text: "Entrar",
      icon: <FaRegUserCircle className="icon-mob" />,
      onClick: openModal,
    },
  ];

  document.querySelectorAll('.scroll-link').forEach(link => {
    link.addEventListener('click', function(event) {
        event.preventDefault();
        var secaoId = this.getAttribute('href').substring(1); 
        rolarParaSecao(secaoId); 
    });
});

function rolarParaSecao(secaoId) {
    var secao = document.getElementById(secaoId);
    secao.scrollIntoView({ behavior: 'smooth' });
}

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll > 80) {
        document.querySelector('#header').style.backgroundColor = '#12111f';
        document.querySelectorAll('.scroll-link').forEach(link => {
          link.style.color = '#fff';
        });
        document.querySelectorAll('.icon-login').forEach(link => {
          link.style.color = '#fff';
        });
        document.querySelectorAll('.hamb-menu').forEach(link => {
          link.style.color = '#fff';
        });
      } else {
        document.querySelector('#header').style.backgroundColor = 'unset';
        document.querySelectorAll('.scroll-link').forEach(link => {
          link.style.color = '#000';
        });
        document.querySelectorAll('.icon-login').forEach(link => {
          link.style.color = '#000';
        });
        document.querySelectorAll('.hamb-menu').forEach(link => {
          link.style.color = '#000';
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <nav id="header" style={{ top: hideHeader ? "-100px" : "0" }}>
      {modalOpen == true && (<LoginModal isOpen={modalOpen} closeModal={closeModal}/>)  }
      <div className="nav-logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar-links-container">
        <a href="#solucoes" className="scroll-link">Soluções</a>
        <a href="#empresa"  className="scroll-link">Empresa</a>
        <a href="#contato"  className="scroll-link">Contato</a>
        <div className="image-login" onClick={openModal}>
          <FaRegUserCircle className="icon-login"/>
        <a className="botao-entrar scroll-link"> Entrar
        </a>
        </div>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 className="hamb-menu" onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
         <List>
  {menuOptions.map((item) => (
    <ListItem key={item.text} disablePadding>
      {item.href ? (
        <a href={item.href} className="href-mobile scroll-link">
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </a>
      ) : (
        <ListItemButton onClick={item.onClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      )}
    </ListItem>
  ))}
</List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

let lastScrollTop = 0;

export default Navbar;
