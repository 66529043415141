import { useState} from "react";
import Modal from "./Modal";
import ProductsList from "./ProductsList"

// grupoDestinatario: 0 => Eugenio
// grupoDestinatario: 1 => Guilherme

  const Work = () => {

  const [selectedItem, setSelectedItem] = useState(null);
  const handleSelected = (index) => {
        setSelectedItem (index) 
  }

  return (
    <div className="work-section-wrapper" id="solucoes">
      <div className="work-section-top">
        <h1 className="primary-heading-serv">Serviços e Produtos</h1>
        <p className="primary-text">
          O que oferecemos a você:
        </p>
      </div>
      <div className="work-section-bottom">
        {ProductsList.map((data, index) => (
          <div className="work-section-info" key={index}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" className="img-card" />
            </div>
            <h3>{data.title}</h3>
            <p>{data.text}</p>
            <button
              className="terciary-button"
              onClick={() => {
                handleSelected(index);
              }}>
              Saiba mais
            </button>
          </div>
        ))}
      </div>
      {selectedItem !== null  && (
        <Modal
          closeModalDescricao={() => setSelectedItem(null)}
          item = {ProductsList[selectedItem]}
        />
      )}
    </div>
  );
};

export default Work;
