import CreditoDireto from "../Assets/card-payment_13094102.png";
import SeguroVei from "../Assets/CarInsu.png";
import ConsignadoPublico from "../Assets/crowdfunding_9235096.png";
import SeguroResi from "../Assets/clipboard_1962592.png";
import ConsignadoPrivado from "../Assets/price-tag_4372591.png";
import Investimento from "../Assets/profits_846043.png";
import PlanejamentoFinanceiro from "../Assets/planning_9203087.png";
import CartaodeCredito from "../Assets/credit-card_657076.png";
import Pesquisas from "../Assets/good-review_5454242.png";

const listaProdutos = [
  {
    image: ConsignadoPrivado,
    title: "Consignado Privado",
    text: "Parcelas descontadas diretamente do salário ou benefício.",
    description:"O consignado privado apresenta taxas de juros mais baixas e parcelas descontadas do salário, oferecendo estabilidade tanto para o solicitante quanto para a instituição financeira. É uma escolha responsável e transparente para trabalhadores do setor privado, garantindo acesso facilitado a recursos financeiros.",
    destinatario: "C"
  },
  {
    image: ConsignadoPublico,
    title: "Consignado Público",
    text: "Solução financeira acessível e conveniente para funcionários do setor público.",
    description:"Oferece taxas de juros reduzidas e parcelas descontadas diretamente da folha de pagamento ou benefício previdenciário, proporcionando segurança e estabilidade financeira para aposentados, pensionistas e funcionários públicos.",
    destinatario: "C"
  },
  {
    image: SeguroResi,
    title: "Seguro Residencial e Automotivo",
    text: "Proteção financeira contra danos ou perdas em casa e veículo.",
    description:"Esse seguro oferece cobertura completa para proteger sua casa e seu veículo contra uma variedade de riscos, como incêndio, roubo e danos. Inclui assistência 24 horas e é essencial para garantir sua tranquilidade e segurança financeira em casa e na estrada.",
    destinatario: "I"
  },
  {
    image: SeguroVei,
    title: "Refinanciamento de Veículos",
    text: "Acesso a dinheiro usando o veículo como garantia.",
    description:"Esta modalidade permite usar o carro como garantia para obter recursos financeiros rapidamente, preservando a posse do veículo, sendo uma alternativa para proprietários que desejam aproveitar o valor do carro sem abrir mão dele, com um processo transparente e acessível.",
    destinatario: "I"
  },
  {
    image: CreditoDireto,
    title: "Crédito Direto",
    text: "Crédito direto baseado na capacidade de pagamento e não exige garantias.",
    description:"Modalidade de empréstimo direto ao consumidor, oferecendo acesso rápido a recursos financeiros com taxas de juros competitivas, mas é importante avaliar cuidadosamente as condições do empréstimo para garantir uma escolha financeiramente responsável e adequada às necessidades individuais.",
    destinario: "C"
  },
  {
    image: Investimento,
    title: "Investimentos",
    text: "Alocação de recursos financeiros em ativos visando retorno futuro.",
    description:"Investimentos representam alocações estratégicas de recursos financeiros visando retornos positivos ao longo do tempo, essenciais para construir riqueza e proteger contra a inflação, personalizados de acordo com objetivos, horizonte de tempo e perfil de risco.",
    destinatario: "I"
  },
  {
    image: PlanejamentoFinanceiro,
    title: "Planejamento Financeiro",
    text: "Estratégias para gerenciar finanças e alcançar metas específicas.",
    description:"Fundamental para alcançar estabilidade financeira, o planejamento envolve definir metas claras, analisar receitas e despesas, e alocar recursos de forma inteligente, garantindo um futuro financeiro seguro e administrando as finanças do presente.",
    destinatario: "I"
  },
  {
    image: CartaodeCredito,
    title: "Cartão de Crédito",
    text: "Oferece conveniência e recompensas como milhas aéreas, cashback e pontos de fidelidade.",
    description:"O cartão de crédito oferece conveniência para fazer compras e pagar por bens e serviços, mesmo sem dinheiro disponível imediatamente, mas deve ser usado com responsabilidade para evitar dívidas excessivas, aproveitando benefícios como cashback e acumulação de milhas.",
    destinatario: "I"
  },
  {
    image: Pesquisas,
    title: "Pesquisas: NPS & CSAT",
    text: "Medimos a probabilidade de recomendação e a satisfação com experiências específicas.",
    description:"Pesquisas de satisfação com seus clientes ou colaboradores internos medindo através de metodologia internacional a satisfação, pontos de melhorias e onde atuar para aumentar a conversão de resultados, melhor satisfação dos colaboradores e melhores serviços a clientes, através de gráficos e análises.",
    destinatario: "C"
  },
];

export default listaProdutos;
