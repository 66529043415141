import React from 'react';
import WhatsApp from "../Assets/whatsapp.png"

const WhatsBtn = () => {
  return (
    <>
      <a href="https://api.whatsapp.com/send?phone=5514991080841&text=Bom%20dia!%20Gostaria%20de%20saber%20mais%20sobre%20os%20produtos%20da%20FAZCREDITO.%20
    Aguardo%20seu%20retorno" className="whatsapp-button" target="_blank">
        <img src={WhatsApp} alt="WhatsApp" />
      </a>
    </>
  );
}

export default WhatsBtn;
