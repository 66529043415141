import { jwtDecode } from "jwt-decode";
import TradingV from "../Components/Trading";

export default function tokenValidator(token) {
  const decodedToken = jwtDecode(token)

  if (!decodedToken.exp) {
    return false;
  }
  
  return decodedToken.exp > Math.floor(Date.now() / 1000);
};